import React, { useState, useEffect } from 'react'
import { Link } from 'react-scroll';
import emailjs from 'emailjs-com';

let HomePage = (props) => {
	let { isSmall } = props
	let [sendMail, setSendMail] = useState(false)
	let [active, setActive] = useState(1)
	let href = '#'

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		setActive(1)
	}, [])

	function sendEmail(e) {
		e.preventDefault();
		emailjs.sendForm('service_7d0vjns', 'template_h112mrl', e.target, 'user_xZyZKqajAEshiAHdi7G4f')
			.then((result) => {
				setSendMail(true)
			}, (error) => {
				console.log(error.text);
			});
	}
	return (
		<div id='home' data-offset="200" data-spy="scroll" data-target=".ow-navigation">


			<div id="site-loader" className="load-complete">
				<div className="loader">
					<div className="loader-inner ball-clip-rotate">
						<div></div>
					</div>
				</div>
			</div>



			{isSmall && <header className="header-main">

				<div className="top-header container-fluid no-padding">

					<div className="container" style={{ marginBottom: 10 }}>
						<ul>
							<li><img src="images/icon/phone-ic.png" alt="Phone Icon" /> <a style={{ fontWeight: "bold" }} href="tel:+905336604718">+90 533 660 47 18</a> </li>
							<li><img src="images/icon/mail-ic.png" alt="Mail Icon" /><a style={{ fontWeight: "bold" }} href="mailto:info@example.com"> fakioglu@hotmail.com</a></li>
							<li><img src="images/icon/mail-ic.png" alt="Mail Icon" /><a style={{ fontWeight: "bold" }} href="mailto:info@example.com"> fakioglutasimacilik@gmail.com</a></li>
						</ul>
						{/* <div className="language-bar pull-right">
							<a href={href}><img src="images/icon/share-ic.png" alt="Share" /></a>
						</div> */}
					</div>

				</div>
			</header>}


			<nav className="menu-block">
				<div className="container" style={{ padding: 0 }}>
					<div className="navbar ow-navigation">
						<div className="navbar-header">
							<button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
								<span className="sr-only">Toggle navigation</span>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
							</button>
							<a className="navbar-brand" style={{ padding: 0 }} href={href}><img style={{ maxWidth: 250, maxHeight: 150 }} src="images/logo.png" alt="Logo" /></a>
						</div>
						<div id="navbar" className="navbar-collapse collapse">
							<ul className="nav navbar-nav">
								<li title="Anasayfa" className={active === 1 ? 'active' : ''}>
									<Link style={{ cursor: "pointer" }} activeClass="active" to="home" spy={true} smooth={true} onClick={() => setActive(1)}>Anasayfa</Link>
								</li>
								<li title="Hakkımızda" className={active === 2 ? 'active' : ''}>
									<Link style={{ cursor: "pointer" }} activeClass="active" to="about" spy={true} smooth={true} onClick={() => setActive(2)}>Hakkımızda</Link>
								</li>
								<li title="Hakkımızda" className={active === 3 ? 'active' : ''}>
									<Link style={{ cursor: "pointer" }} activeClass="active" to="rentacar" spy={true} smooth={true} onClick={() => setActive(3)}>Araç Kiralama</Link>
								</li>
								<li title="Referanslar" className={active === 4 ? 'active' : ''} >
									<Link style={{ cursor: "pointer" }} activeClass="active" to="reference" spy={true} smooth={true} onClick={() => setActive(4)}>Referanslar</Link>
								</li>
								<li title="İletişim" className={active === 5 ? 'active' : ''} >
									<Link style={{ cursor: "pointer" }} activeClass="active" to="contact" spy={true} smooth={true} onClick={() => setActive(5)}>İletişim</Link>
								</li>
								{/* <li className="dropdown">
									<a href="services.html" title="Fleet" className="dropdown-toggle" role="button" aria-haspopup="true" aria-expanded="false">Servisler</a>
									<i className="ddl-switch fa fa-angle-down"></i>
									<ul className="dropdown-menu">
										<li><a href="estimation-form.html">Quote</a></li>
									</ul>
								</li>
								<li className="dropdown">
									<a href="fleet.html" title="Fleet" className="dropdown-toggle" role="button" aria-haspopup="true" aria-expanded="false">Fleet</a>
									<i className="ddl-switch fa fa-angle-down"></i>
									<ul className="dropdown-menu">
										<li><a href="fleet.html">Fleet</a></li>
										<li><a href="fleet-grid.html">Fleet Grid</a></li>
									</ul>
								</li>
								<li className="dropdown">
									<a href="blog.html" title="Blog" className="dropdown-toggle" role="button" aria-haspopup="true" aria-expanded="false">Blog</a>
									<i className="ddl-switch fa fa-angle-down"></i>
									<ul className="dropdown-menu">
										<li><a href="blog.html">Blog</a></li>
										<li><a href="single-blog.html">Single Blog</a></li>
									</ul>
								</li>
								<li className="dropdown">
									<a href={href} title="Pages" className="dropdown-toggle" role="button" aria-haspopup="true" aria-expanded="false">Pages</a>
									<i className="ddl-switch fa fa-angle-down"></i>
									<ul className="dropdown-menu">
										<li><a href="errorpage.html">404</a></li>
										<li><a href="coming-soon.html">Coming Soon</a></li>
									</ul>
								</li>
								<li><a href="contact-us.html" title="Contact">Contact</a></li> */}
							</ul>
						</div>
					</div>
				</div>
			</nav>


			<div id="photo-slider" className="photo-slider container-fluid no-padding">

				<div id="main-carousel" className="carousel slide carousel-fade col-offset-2" data-ride="carousel">
					<div className="carousel-inner" role="listbox">
						<div className="item active">
							<img src="images/slider-image/slider-1.png" alt="slider-1" />
							<div className="carousel-caption">
								<h1>Her zaman güvenilir hizmet</h1>
							</div>
						</div>
						<div className="item">
							<img src="images/slider-image/slider3.png" alt="slider-1" />
							<div className="carousel-caption">
								<h1>Kurumsal Araç Kiralama</h1>
							</div>
						</div>
						<div className="item">
							<img src="images/slider-image/slider4.png" alt="slider-1" />
							<div className="carousel-caption">
								<h1>Kurumsal Araç Kiralama</h1>
							</div>
						</div>
					</div>
					<a className="left carousel-control" href="#main-carousel" role="button" data-slide="prev">
						<i className="fa fa-angle-left"></i>
						<span className="sr-only">Previous</span>
					</a>
					<a className="right carousel-control" href="#main-carousel" role="button" data-slide="next">
						<i className="fa fa-angle-right"></i>
						<span className="sr-only">Next</span>
					</a>
				</div>
			</div>


			<div className="services container-fluid no-padding">

				<div className="container">
					<div className="services-box">
						{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i =>
							<div key={i} className="col-md-12">
								<div className="services-content">
									<img style={{ height: 350, objectFit: 'cover' }} src={"images/services/" + i + ".jpeg"} alt="services" />
									<h3>Servislerimiz</h3>
								</div>
							</div>
						)}
					</div>
					<div style={{ backgroundColor: "#f7f8f9", width: "100%" }} className="container" id="about"><br /></div>
				</div>
			</div>

			<div style={{ backgroundColor: "#f7f8f9", width: "100%" }} className="container"><br /></div>
			<div style={{ backgroundColor: "#f7f8f9", width: "100%" }} className="container"><br /></div>
			<div style={{ backgroundColor: "#f7f8f9", width: "100%" }} className="container"><br /></div>
			<div style={{ backgroundColor: "#f7f8f9", width: "100%" }} className="container"><br /></div>
			<div style={{ backgroundColor: "#f7f8f9", width: "100%" }} className="container"><br /></div>

			<div className="why-we-are-section container-fluid no-padding">

				<div className="features-block">
					<div className="section-padding"></div>

					<div className="section-header">
						<h3>Hakkımızda</h3>
						<p>Kahramanmaraş'ta kurulan Fakıoğlu Taşımacılık Tem. Teks. Gıd. İnş. Met. LTD. ŞTİ. 2008 yılından günümüze kadar başta taşımacılık sektöründe olmak üzere tekstil malzemeleri ve birçok alanda hizmet vermektedir.</p>
						<p>Kurucumuz <span style={{ fontSize: "medium", fontWeight: "bold", color: "white" }}>MEHMET ÖZDEMİR</span> liderliğinde özellikle taşımacılık sektöründe köklü firmalardan birisi olmayı başarmıştır.</p>
						<p>Firmamız personel taşımacılığı, Kurumsal araç kiralama (binek), VIP araç kiralama hizmetlerini vermektedir.</p>
						<p> Aynı zamanda 2. el tekstil makina alım satım sektöründe de aktif bir şekilde hizmet vermektedir. </p>
						<p>10 yılı aşan deneyimi, yeni yatırımları her zamanki azmi ve coşkusuyla ülkesi ve bölge insanı için büyümeye devam edecektir.</p>
					</div>

					<div className="features-content row">
						<div className="col-md-4 features-box">
							<i><img src="images/icon/about-ic.png" alt="commitment" /><span>1</span></i>
							<div className="features-box-content">
								<h4>Kaliteli ve Konforlu Taşımacılık</h4>
							</div>
						</div>
						<div className="col-md-4 features-box">
							<i><img src="images/icon/user.png" alt="commitment" /><span>2</span></i>
							<div className="features-box-content">
								<h4>İnsan Odaklı Hizmet</h4>
							</div>
						</div>
						<div className="col-md-4 features-box">
							<i><img src="images/icon/like.png" alt="commitment" /><span>3</span></i>
							<div className="features-box-content">
								<h4>Güvenilirlik</h4>
							</div>
						</div>
					</div>
					<div className="section-padding"></div>
				</div>

				<div className="request-quote">
					<div className="section-padding"></div>

					<div className="section-header">
						<i><img src="images/icon/quote-ic.png" alt="quote ic" /></i>
						<h3>Bizimle İletişime Geçin</h3>
					</div>
					<form className="quote-form" onSubmit={sendEmail}>
						<div className="form-group col-md-12">
							<input type="text" className="form-control" placeholder="Ad Soyad" name="name" required />
						</div>
						<div className="form-group col-md-12">
							<input type="text" className="form-control" name="from_phone" placeholder="Telefon" />
						</div>
						<div className="form-group col-md-12">
							<input type="text" className="form-control" name="from_mail" placeholder="Mail Adresi" required />
						</div>
						<div className="form-group col-md-12">
							<textarea className="form-control" name="message" placeholder="Message"></textarea>
						</div>
						<div className="form-group col-md-12 col-sm-12 col-xs-12">
							<button className="btn pull-right" style={{ width: "100%" }} disabled={sendMail}>Mesajı Gönder<i><img src="images/icon/mail-ic.png" alt="About Ic" /></i></button>
						</div>
					</form>
					<div className="section-padding"></div>
					{sendMail && <div className="col-md-12 alert alert-primary" style={{ color: "white", fontWeight: "bold", backgroundColor: "#1369BC", width: "100%", borderRadius: 5 }} role="alert"> Mesajınız Gönderilmiştir. </div>}
				</div>
			</div>

			<div id="rentacar" className="container"><br /></div>
			<div className="container"><br /></div>
			<div className="container"><br /></div>
			<div className="container"><br /></div>

			<div className="blog-section container-fluid no-padding">
				<div className="section-padding"></div>

				<div className="container">

					<div className="section-header">
						<h3 style={{ textAlign: "center" }}>Kurumsal Araç Kiralama </h3>
					</div>
					<div className="col-md-6">
						<div className="entry-cover">
							<a href={href}><img style={{ height: isSmall ? "" : 400 }} className="services-content" src="images/services-image/peugeot.jpg" alt="blog-1" /></a>
							<h3 style={{ textAlign: "center" }}>Peugeot 301</h3>
						</div>
					</div>

					<div className="col-md-6">
						<div className="entry-cover">
							<a href={href}><img style={{ height: isSmall ? "" : 400 }} className="services-content" src="images/services-image/vito.jpg" alt="blog-1" /></a>
							<h3 style={{ textAlign: "center" }}>Mercedes Vito</h3>
						</div>
					</div>

				</div>
				<div className="container">
					<div className="col-md-6">
						<div className="entry-cover">
							<a href={href}><img style={{ height: isSmall ? "" : 400 }} className="services-content" src="images/services-image/sprinter.png" alt="blog-1" /></a>
							<h3 style={{ textAlign: "center" }}>Mercedes Sprinter</h3>
						</div>
					</div>

					<div className="col-md-6">
						<div className="entry-cover">
							<a href={href}><img style={{ height: isSmall ? "" : 400 }} className="services-content" src="images/services-image/fluence.png" alt="blog-1" /></a>
							<h3 style={{ textAlign: "center" }}>Renault Fluence</h3>
						</div>
					</div>
				</div>
				<div id="reference" className="section-padding"></div>
			</div>


			<div className="testimonials-section container-fluid no-padding">
				<div className="section-padding"></div>

				<div className="container">

					<div className="section-header-1">
						<h3>Referanslarımız</h3>
					</div>
					<div className="testimonials-box">
						<div className="col-md-12">
							<div className="testimonials-content">
								<i><img src="images/testimonials/has-dokuma.png" alt="testimonials-1" /></i>
								{/* <p>Cum sociis natoque penatibus et magnis dis parturient salos montes ascetur ridiculus. mus. Nultl dui. Fusce feugiat male suada odio. Morbi nunc odio gravida at cursus. service with robust ideas. </p>
								<h3>Fatih Tekce <span>(Ceo Of The Boston Group)</span></h3> */}
							</div>
						</div>
						<div className="col-md-12">
							<div className="testimonials-content">
								<i><img src="images/testimonials/gemciler.png" alt="testimonials-1" /></i>
								{/* <p>Cum sociis natoque penatibus et magnis dis parturient salos montes ascetur ridiculus. mus. Nultl dui. Fusce feugiat male suada odio. Morbi nunc odio gravida at cursus. service with robust ideas. </p>
								<h3>William Smith <span>(Ceo Of The SAS Institute)</span></h3> */}
							</div>
						</div>
						<div className="col-md-12">
							<div className="testimonials-content">
								<i><img src="images/testimonials/madosa.png" alt="testimonials-1" /></i>
								{/* <p>Cum sociis natoque penatibus et magnis dis parturient salos montes ascetur ridiculus. mus. Nultl dui. Fusce feugiat male suada odio. Morbi nunc odio gravida at cursus. service with robust ideas. </p>
								<h3>William Smith <span>(Ceo Of The SAS Institute)</span></h3> */}
							</div>
						</div>
					</div>
				</div>
			</div>


			<div className="partners-section container-fluid no-padding">

				<div className="container">
					<div className="section-padding"></div>
					<div className="partners-logos" style={{ display: "flex", justifyContent: "center" }}>
						<div className="item"><a href={href} title="Logo"><img src="images/testimonials/has-dokuma.png" alt="Logo 1" /></a></div>
						<div className="item"><a href={href} title="Logo"><img src="images/testimonials/gemciler.png" alt="Logo 2" /></a></div>
						<div className="item"><a href={href} title="Logo"><img src="images/testimonials/madosa.png" alt="Logo 3" /></a></div>
					</div>
					<div className="section-padding"></div>
				</div>
			</div>


			<div id="contact" className="call-out container-fluid no-padding">
				<div className="container">
					<div className="col-md-6 col-sm-6 call">
						<i><img src="images/icon/phone-ic-26x26.png" alt="phone ic 26x26" /></i>
						<h3><span>HERHANGİ BİR SORUNUZ MU VAR? TEKLİF Mİ İSTİYORSUNUZ?</span><b><a style={{ fontWeight: "bold" }} href="tel:+905336604718">+90 533 660 47 18</a></b></h3>
					</div>
					<div className="col-md-6 col-sm-6">
						<i><img src="images/icon/truck-ic.png" alt="truck ic" /></i>
						<h3><span>Adres</span><p style={{ fontSize: isSmall ? "large" : "xlarge" }}>Şazibey Mah. Gazi Mustafa Kemal Bulvarı No:30/A Onikişubat/Kahramanmaraş</p></h3>
					</div>
				</div>
			</div>

			<footer className="footer-main">
				<div className="bottom-footer container-fluid no-padding">
					<p className="copyright">&copy; 2021 Fakıoğlu Taşımacılık Tem. Teks. Gıd. İnş. Met. LTD. ŞTİ.</p>
				</div>
			</footer>

		</div >

	)
}

export default HomePage;