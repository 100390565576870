import React, { useEffect } from 'react'
import { setGlobal, useGlobal } from 'reactn'
import { HomePage } from './pages'
import OneSignal from 'react-onesignal';

setGlobal({
  isSmall: window.innerWidth <= 1024,
  windowWidth: window.innerWidth,
  signal: localStorage.getItem("notification")
});



let App = () => {
  let [isSmall, setIsSmall] = useGlobal('isSmall')
  let [signal, setSignal] = useGlobal("signal")
  let [windowWidth, setWindowWidth] = useGlobal('windowWidth')

  useEffect(() => {
    OneSignal.init({ appId: 'fdaab99b-7ac3-43f1-815f-555da5f8e22a' }).then(() => {
      OneSignal.showSlidedownPrompt().then(() => {
        setSignal(true);
        localStorage.setItem("signal", true)
      });
    })

    window.onresize = () => {
      setIsSmall(window.innerWidth < 1024)
      setWindowWidth(window.innerWidth)
    }
  }, [setIsSmall, setWindowWidth])

  return (
    <HomePage isSmall={isSmall} windowWidth={windowWidth} />
  );
}

export default App;
